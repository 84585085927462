import React, { FC } from 'react';

/**
 * Combines multiple store providers
 */
export const combineStoreProviders = (
    ...components: FC<{ children: React.ReactNode }>[]
): FC<{ children: React.ReactNode }> => {
    return components.reduce(
        (AccumulatedComponents, CurrentComponent) => {
            const WrappedComponent: FC<{ children: React.ReactNode }> = ({ children }) => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
            WrappedComponent.displayName = `Combined(${AccumulatedComponents.displayName}, ${CurrentComponent.displayName})`;
            return WrappedComponent;
        },
        ({ children }) => <>{children}</>,
    );
};
