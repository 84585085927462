import React from 'react';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import {
    COLOR_ERROR,
    COLOR_TEXT,
    COLOR_TEXT_MEDIUM,
    SECONDARY_MEDIUM,
    FLOQ_MAIN,
    UI_GREY_1,
    COLOR_WHITE,
    COLOR_DISABLED,
    COLOR_BLACK,
    SECONDARY_DARK,
    COLOR_SUCCESS,
} from '../utils';

const themeStructure: ThemeOptions = {
    palette: {
        primary: {
            main: FLOQ_MAIN,
        },
        secondary: {
            main: SECONDARY_MEDIUM,
        },
        error: {
            main: COLOR_ERROR,
        },
        divider: SECONDARY_MEDIUM,
        info: {
            main: COLOR_TEXT_MEDIUM,
        },
    },
    typography: {
        fontFamily: ['Cabin', 'Roboto'].join(','),
        h1: {
            fontFamily: 'Cabin',
            fontWeight: 700,
            fontSize: '48px',
            lineHeight: '48px',
            color: COLOR_TEXT,
            marginBottom: 8,
        },
        h2: {
            fontFamily: 'Cabin',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '28px',
            color: COLOR_TEXT,
            marginBottom: 8,
        },
        h3: {
            fontFamily: 'Cabin',
            fontWeight: 700,
            fontSize: '21px',
            lineHeight: '24px',
            color: COLOR_TEXT,
            marginBottom: 8,
        },
        h4: {
            fontFamily: 'Cabin',
            fontWeight: 700,
            fontSize: '17px',
            lineHeight: '24px',
            color: COLOR_TEXT,
            marginBottom: 8,
        },
        h5: {
            fontFamily: 'Cabin',
            fontWeight: 700,
            fontSize: '15px',
            lineHeight: '20px',
            color: COLOR_TEXT,
            marginBottom: 8,
        },
        body1: {
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '24px',
            color: COLOR_TEXT,
        },
        body2: {
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '20px',
            color: COLOR_TEXT_MEDIUM,
        },
        subtitle1: {
            fontFamily: 'Roboto',
            fontSize: '13px',
            lineHeight: 1.54,
            color: COLOR_TEXT_MEDIUM,
        },
        subtitle2: {
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: 1.54,
            color: COLOR_DISABLED,
        },
        button: {
            textTransform: 'none',
            fontFamily: 'Roboto',
            fontSize: '15px',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 40,
                    padding: '8px 16px',
                    borderRadius: '6px',
                    boxShadow: 'none',

                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: COLOR_BLACK,
                    padding: '4px 8px 4px 8px',
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    lineHeight: '16px',
                },
                arrow: {
                    color: COLOR_BLACK,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    boxShadow:
                        '0 2px 8px 0 rgba(0, 0, 0, 0.15), 0 12px 16px 0 rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, 0.15)',
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    minWidth: 200,
                    padding: 4,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    height: 48,
                    borderRadius: 6,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    '& > span': {
                        margin: 0,
                        fontWeight: 500,
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    height: 40,
                    borderRadius: 4,
                    padding: '8px 16px',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'Roboto',
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderTop: `1px solid ${UI_GREY_1}`,
                    borderBottom: `1px solid ${UI_GREY_1}`,
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
                bar: {
                    borderRadius: 6,
                    backgroundColor: FLOQ_MAIN,
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        color: COLOR_TEXT,
                    },
                    '&:focus': {
                        color: COLOR_TEXT,
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    border: '1px solid white',
                    height: 72,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: SECONDARY_MEDIUM,
                    border: 'none',
                    '&:first-of-type': {
                        borderRadius: '10px 0 0 10px',
                    },
                    '&:last-child': {
                        borderRadius: '0 10px 10px 0',
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    overflow: 'hidden',
                    border: `2px solid ${COLOR_WHITE}`,
                    backgroundColor: SECONDARY_MEDIUM,
                    '&.Mui-focused': {
                        backgroundColor: COLOR_WHITE,
                        border: `2px solid ${FLOQ_MAIN}`,
                        borderRadius: '6px',
                    },
                    '&.Mui-error': {
                        border: `2px solid ${COLOR_ERROR}`,
                        borderRadius: '6px',
                        backgroundColor: COLOR_WHITE,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& fieldset': { border: 'none' },
                    borderRadius: '6px',
                    overflow: 'hidden',
                    border: `2px solid ${COLOR_WHITE}`,
                    backgroundColor: SECONDARY_MEDIUM,
                    '&.Mui-focused': {
                        backgroundColor: COLOR_WHITE,
                        border: `2px solid ${FLOQ_MAIN}`,
                        borderRadius: '6px',
                    },
                    '&.Mui-error': {
                        border: `2px solid ${COLOR_ERROR}`,
                        borderRadius: '6px',
                        backgroundColor: COLOR_WHITE,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.6)',
                    padding: '4px',
                    '&.MuiInputLabel-shrink': {
                        padding: '2px',
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '8px',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '0 24px',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '24px',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                colorSuccess: {
                    borderColor: COLOR_SUCCESS,
                    color: COLOR_SUCCESS,
                    backgroundColor: COLOR_WHITE,
                    borderWidth: '2px',
                    fontWeight: 500,
                    fontSize: '15px',
                },
                colorError: {
                    color: COLOR_ERROR,
                    backgroundColor: COLOR_WHITE,
                    borderWidth: '2px',
                    fontWeight: 500,
                    fontSize: '15px',
                },
                colorWarning: {
                    backgroundColor: COLOR_WHITE,
                    borderWidth: '2px',
                    fontWeight: 500,
                    fontSize: '15px',
                },
                colorInfo: {
                    backgroundColor: COLOR_WHITE,
                    color: COLOR_DISABLED,
                    borderColor: COLOR_DISABLED,
                    borderWidth: '2px',
                    fontWeight: 500,
                    fontSize: '15px',
                },
                root: {
                    borderRadius: '4px',
                    backgroundColor: SECONDARY_DARK,
                    padding: '4px',
                },
                label: {
                    fontSize: '15px',
                    fontFamily: 'Roboto',
                },
                labelSmall: {
                    fontSize: '13px',
                    fontFamily: 'Roboto',
                },
                deleteIcon: {
                    fontSize: '20px',
                    borderRadius: '1px',
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    fontWeight: 400,
                },
                filledInfo: {
                    backgroundColor: '#F5F0FE',
                    color: COLOR_TEXT,
                    '& .MuiAlert-icon': {
                        color: FLOQ_MAIN,
                    },
                },
                standardSuccess: {
                    color: COLOR_TEXT,
                },
                standardError: {
                    color: COLOR_TEXT,
                },
                standardWarning: {
                    color: COLOR_TEXT,
                },
                standardInfo: {
                    color: COLOR_TEXT,
                },
            },
        },
    },
};

const theme = createTheme(themeStructure);

type ThemeFactoryWrapperProp = {
    children: React.ReactNode;
};

export const ThemeFactoryWrapper = ({ children }: ThemeFactoryWrapperProp) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
