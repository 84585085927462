import React, { ComponentType } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loader } from '../components';
import styled from 'styled-components';
import { UI_GREY_1 } from '../utils';
import Box from '@mui/material/Box';

interface ProtectedRouteProps {
    component: ComponentType;
}

export const ProtectedRoute = ({ component }: ProtectedRouteProps) => {
    const params = new URLSearchParams(window.location.search);

    // Accept user invitation flow;
    const organization = params.get('organization');
    const invitation = params.get('invitation');

    const Cp = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <Box
                flex={'1 0 100%'}
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                minHeight={'calc(100vh)'}
                sx={{ backgroundColor: UI_GREY_1 }}
            >
                <Loader />
            </Box>
        ),
        ...(organization && invitation && { loginOptions: { organization, invitation } }),
    });
    return <Cp></Cp>;
};
